import React, { useState, useEffect } from "react"
import { Link as GatsbyLink } from 'gatsby'

import LayoutAccount from "../components/layouts/layoutAccount"
import LayoutPublic from "../components/layouts/layoutPublic"

import { Box, Button, Center, Flex, Image, Stack, Text, Heading, Spinner } from "@chakra-ui/react"
import { FormattedMessage } from 'react-intl';
import Seo from '../components/Seo/';
import logo from '../images/logo.svg'
import withLocation from "../utils/withLocation";
import { UserContext } from '../components/userContext';
import Debug from '../utils/Debug';
import AdCardLandscape from '../components/ad/Landscape'
import { wrapperPadding } from "../utils/wrapperPadding"

const contentful = require("contentful");

const client = contentful.createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE,
  environment: "master",
  accessToken: process.env.GATSBY_CONTENTFUL_TOKEN,
});

const FavoritesHubTpl = (props) => {

  const userContext = React.useContext(UserContext);
  const user = userContext ? userContext.user : null;
  const [ads, setAds] = React.useState(false);

  useEffect(
    () => {

      if (user && user.favorites && user.favorites.length) {
        client
          .getEntries(
            {
              content_type: "ad",
              locale: process.env.GATSBY_LANG,
              limit: 10,
              'sys.id[in]': user.favorites.map(item => item.cmsDocumentId).join(','),
            }
          )
          .then(response =>
            setAds(response.items)
          )
          .catch(console.error)
      }

    }, [user]
  )

  const FullDate = (props) => {
    const date = new Date(props.date);
    return (
      date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    )
  }



  const title = {
    "fr": "Vos annonces préférées"
    ,
    "en": "Your favorites ads"
  }
  const description = {
    "fr": "Je vous présente ici vos annonces favorites, cela vous permet d'y ré-accéder simplement"
    ,
    "en": "I present here your favourite ads, so that you can easily access them again"
  };

  return (
    <LayoutPublic lang={process.env.GATSBY_LANG}>
      <Seo
        title={
          title[process.env.GATSBY_LANG]
        }
        description={
          description[process.env.GATSBY_LANG]
        }
        slug={'/'}
      />
      <Box>
        <Flex
          py='2rem'
          borderBottom={{ base: 'none', lg: 'solid 1px' }}
          borderBottomColor={{ lg: 'gray.100' }}
          justifyContent='space-between'
          {...wrapperPadding()}
        >
          <Box>
            <Button
              as={GatsbyLink}
              to="/"
              variant='outline'
            >
              <FormattedMessage id="main.back" />
            </Button>
          </Box>
          <Box>
            <Image src={logo} w="50px" h="50px" />
          </Box>
        </Flex>
        <Box
          minH={`calc(100vh - 115px)`}
        >

          <Box
            maxW={'750px'}
            mx='auto'
            py={{ base: 4, lg: 6 }}
            {...wrapperPadding()}

          >

            {ads && ads.length ? ads.map(edge =>

              <AdCardLandscape
                to={edge.fields.slug}
                contentful_id={edge.sys.id}

                openAction={
                  () => {
                    this.setState({ adSingleID: edge.sys.id })
                  }
                }

                action={edge.fields.action}
                // isInIframe={this.props.openSelf}
                openSelf={false}
                name={edge.fields.name}
                price={edge.fields.price}
                currency={edge.fields.currency}
                brand={(edge.fields.refBrand) ? edge.fields.refBrand.fields.name : null}
                region={edge.fields.department}
                country={edge.fields.country}
                key={`landscapelarge-${edge.sys.id}`}
                images={edge.fields.pictures}
                seller={edge.fields.user ? true : false}
                publicationDate={<FullDate date={edge.fields.publicationDate} />}
                isPremium={edge.fields.isPremium}
              />
            )
              :
              <Center>
                <Text>
                  Je cherche vos favoris
                </Text>
                <Spinner ml={2} size='sm' color='gray.600' />
              </Center>
            }
          </Box>
        </Box>
      </Box>
    </LayoutPublic>
  )
}
export default withLocation(FavoritesHubTpl)